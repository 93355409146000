import React from "react";
import logo from "../images/logo.png";

import "../css/style.scss";

function ComingSoon() {
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="flex items-center text-5xl font-extrabold leading-tighter tracking-tighter">
        <img alt="logo" src={logo} className="h-16 inline-block mr-4" />
        RAIO
      </div>
      <div className="mb-16 mt-8 text-gray-500 font-extralight text-3xl">
        Coming Soon
      </div>
    </div>
  );
}

export default ComingSoon;
